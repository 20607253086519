
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

const initialData = {
  name: '',
  email: '',
  phone: '',
  businessType: '',
  message: '',
}

export default {
  name: 'PageSectionContact',
  mixins: [validationMixin],
  data() {
    return {
      isSubmitted: false,
      isLoading: false,
      data: {
        ...initialData,
      },
    }
  },
  methods: {
    async createActiveCampaignContact() {
      try {
        const { contact } = await this.$axios.$post('/ac/contact/sync', {
          contact: {
            ...this.data,
            firstName: this.data.name.split(' ')[0],
            lastName: this.data.name.split(' ')[1],
            fieldValues: [{ field: 1, value: this.data.businessType }],
          },
        })

        return contact
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async createActiveCampaignEvent() {
      try {
        await this.$axios.post(
          '/apiproxy?apiurl=https://trackcmp.net/event',
          new URLSearchParams({
            actid: 610928896,
            key: '80a3b35dd86b38bdb1d7b54a3a7edb17e69a584d',
            event: 'gd_contact_form',
            visit: JSON.stringify({
              email: this.data.email,
            }),
          })
        )
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async createActiveCampaignNote(contact) {
      try {
        await this.$axios.post('/ac/notes', {
          note: {
            note: `
                Contact form submitted on ${
                  new Date().getMonth() + 1
                }/${new Date().getDate()}/${new Date().getFullYear()}

                Name: ${this.data.name}
                Phone: ${this.data.phone}
                Email: ${this.data.email}
                Business Type: ${this.data.businessType}
                Message: ${this.data.message}
              `,
            relid: contact.id,
            reltype: 'Subscriber',
          },
        })
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    },

    async onSubmit(e) {
      if (this.isLoading) return
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isLoading = true

      const contact = await this.createActiveCampaignContact()
      if (contact) this.createActiveCampaignNote(contact)
      this.createActiveCampaignEvent()

      this.isSubmitted = true
      this.isLoading = false
    },
  },
  validations: {
    data: {
      name: { required },
      phone: { required },
      email: { required, email },
      businessType: { required },
      message: { required },
    },
  },
}
