
const ERROR_TEMPLATE_MAP = {
  email: 'Must be a valid email address',
  required: 'This is required',
  numeric: 'Must contain only numbers',
  mustBeLength5: 'Must contain 5 characters',
}

export default {
  name: 'Input',

  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    pattern: {
      type: [String, undefined],
      default: undefined,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'email', 'tel', 'number'].includes(value),
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    validations: {
      type: [Object, undefined],
      default: undefined,
    },
  },

  computed: {
    errors() {
      if (!this.invalid) return []

      return Object.keys(this.validations.$params).reduce(
        (errors, validator) => {
          if (!this.validations[validator]) {
            const compiled = ERROR_TEMPLATE_MAP[validator]
            errors.push(compiled)
          }
          return errors
        },
        []
      )
    },
    invalid() {
      if (!this.validations) return false
      return this.validations.$dirty && this.validations.$invalid
    },
    localValue: {
      get() {
        return this.value
      },
      set(localValue) {
        this.validations && this.validations.$touch()
        this.$emit('input', localValue)
      },
    },
    classes() {
      return {
        [`input--${this.type}`]: true,
        [`input--${this.theme}`]: true,
        'input--error': this.invalid,
      }
    },
  },

  methods: {
    onBlur() {
      this.validations && this.validations.$touch()
    },
  },
}
