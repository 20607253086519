
export default {
  name: 'Heading',
  props: {
    as: {
      type: String,
      default: 'h1',
    },
  },
}
