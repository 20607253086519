
export default {
  name: 'Section',
  props: {
    flex: {
      type: String,
      default: 'column',
    },
  },
  computed: {
    classes() {
      const className = 'section'
      return {
        [className]: true,
        [`${className}--flex-${this.flex}`]: !!this.flex,
      }
    },
  },
}
