import { render, staticRenderFns } from "./PageSectionContact.vue?vue&type=template&id=743e1752&scoped=true"
import script from "./PageSectionContact.vue?vue&type=script&lang=js"
export * from "./PageSectionContact.vue?vue&type=script&lang=js"
import style0 from "./PageSectionContact.vue?vue&type=style&index=0&id=743e1752&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743e1752",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Heading.vue').default,InputsInput: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Inputs/Input.vue').default,InputsTextarea: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Inputs/Textarea.vue').default,Button: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Button.vue').default,Section: require('/home/travis/build/Polumathes/LTNG-GDFreight-Frontend/components/Section.vue').default})
